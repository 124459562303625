import React from 'react';
import "./option-button.css"

const OptionButton = ({optionValue, handleClick, optionKey}) => {
    return (
        <div className="option-button">
       <button className="option-button__button" onClick={() => handleClick(optionKey)}><div className="option-buton__option-key">{optionKey}</div> - {optionValue} </button> 
        </div>
    );
};

export default OptionButton;