import React, { useEffect} from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import ImageUploadCard from "../upload-image/upload-image.component";


const AdditionalDetails = ({
  setStateImageUrl,
  setStateImageFile,
  setCustomerMessage,
  customerMessage,
  stateImageUrl,
  handleNext,
  selectedOption,
  cachedOption,
  handleBack
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const option = selectedOption ? selectedOption : cachedOption;

  const selectedOptionIsAOrB = () => {
    return option === "A" || option === "B"
  }

useEffect(() => {
  selectedOption === 'C' && handleNext()
}, [handleNext, selectedOption])

useEffect(() => {
  cachedOption === "C" && handleBack()
}, [handleBack, cachedOption])

  return (
    <div>
    {option === "A" && <ImageUploadCard
      setStateImageUrl={setStateImageUrl}
      setStateImageFile={setStateImageFile}
      stateImageUrl={stateImageUrl}
    />}
      {selectedOptionIsAOrB() &&
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classes.root}
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-multiline-static"
            label={t("jewelryDetails.Your jewelry details")}
            placeholder={t("jewelryDetails.I want to make a")}
            onChange={(e) => setCustomerMessage(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            value={customerMessage}
          />
        </div>
      </form>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
      maxWidth: "50ch",
    },
  },
  options: {
    display: "flex",
    flexDirection: "column",
    padding: "0 5rem 0 5rem",
    "@media (max-width: 768px)": {
      padding: "0"
    }
  }

}));

export default AdditionalDetails;
