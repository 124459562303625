import React, { useEffect, useState } from "react";
import "./App.css";
import GetProjectDetails from "./pages/get-project-details/get-project-details";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { getPageDetailsWithSubdomain } from "./firebase/firebase-utils";
import { Helmet } from "react-helmet";

let primaryColor = "#b55c53";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const App = ({ ReactGa, ReactPixel }) => {
  const [pageTitle, setPageTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [jewelryTypes, setJewelryTypes] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [companyMail, setCompanyMail] = useState("");

  useEffect(() => {
    const subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;

    getPageDetailsWithSubdomain(subdomain).then((pageDetails) => {
      setPageTitle(pageDetails.pageTitle);
      setCompanyName(pageDetails.name);
      setSiteUrl(pageDetails.siteUrl);
      setTemplateId(pageDetails.templateId);
      setJewelryTypes(pageDetails.jewelryTypes);
      setLogoUrl(pageDetails.logoUrl);
      setCompanyMail(pageDetails.companyMail);
      primaryColor = pageDetails.primaryColor;
    });
  }, []);
  return (
    <div className="App">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <GetProjectDetails
          companyName={companyName}
          siteUrl={siteUrl}
          templateId={templateId}
          jewelryTypes={jewelryTypes}
          logoUrl={logoUrl}
          companyMail={companyMail}
          ReactGa={ReactGa}
          ReactPixel={ReactPixel}
        />
      </ThemeProvider>
    </div>
  );
};

export default App;
