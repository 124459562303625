import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { hotjar } from "react-hotjar";

// google analytics setup
ReactGA.initialize("UA-152438836-1");
ReactGA.pageview(window.location.pathname + window.location.search);

// pixel setup
const options = {
  autoConfig: true,
  debug: true, // enable logs
};
ReactPixel.init("2344625309100215", options);
ReactPixel.pageView();

// hotjar setup
hotjar.initialize(2082693, 6);

ReactDOM.render(
  <React.StrictMode>
    <App ReactPixel={ReactPixel} ReactGa={ReactGA} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
