import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDQiQ5mgF_CVhgB7Ajv9QVgenPN16kA5ng",
  authDomain: "tidan-269212.firebaseapp.com",
  databaseURL: "https://tidan-269212.firebaseio.com",
  projectId: "tidan-269212",
  storageBucket: "tidan-269212.appspot.com",
  messagingSenderId: "1065020645476",
  appId: "1:1065020645476:web:db0e13fcd752cd4e3dbf71",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

export const firestore = firebase.firestore();

const defaultCompany = {
  name: "TIDAN",
  jewelryTypes: [
    "engagement ring",
    "wedding ring",
    "signet ring",
    "cufflinks",
    "pendant",
  ],
  logoUrl:
    "https://firebasestorage.googleapis.com/v0/b/tidan-269212.appspot.com/o/branding%2Fapp.svg?alt=media",
  primaryColor: "#b55c53",
  templateId: "2",
  pageTitle: "TIDAN - Design your project with us",
  siteUrl: "https://tidanapp.com",
  companyMail: "design@tidanapp.com",
};

export const createOrderConfirmationMail = (client) => {
  firestore
    .collection("mail")
    .add({
      templateId: 2,
      to: client.customerEmail,
      timeStamp: new Date(),
      params: {
        ...client,
      },
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

export const createNewClientAlertMail = (client, companyMail) => {
  firestore
    .collection("mail")
    .add({
      templateId: 3,
      to: companyMail,
      timeStamp: new Date(),
      params: {
        ...client,
      },
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

export const getPageDetailsWithSubdomain = (subdomain) => {
  return new Promise((resolve, reject) => {
    if (!subdomain) {
      resolve(defaultCompany);
    }
    const docRef = firestore.collection("sites").doc(subdomain);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.error("No such document! Returning defaultCompany");
          resolve(defaultCompany);
        }
      })
      .catch((error) => {
        reject(new Error("Error getting document: " + error));
      });
  });
};
