import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";

import OptionButton from "../option-button/option-button.jsx"

const DetailOptions = ({setSelectedOption, handleNext, selectedOption, setShowNextButton}) => {
    const { t } = useTranslation();
  const classes = useStyles();

  const options = [
    {optionValue: t('additionalDetails.I have images of the jewelry I want to make'), optionKey: 'A'},
    {optionValue: t('additionalDetails.I have ideas or hints that I can share'), optionKey: 'B'},
    {optionValue: t('additionalDetails.I have no idea but want to make an amazing piece'), optionKey: 'C'}
  ]

  useEffect(() => {
    selectedOption && handleNext()
  })

  useEffect(() => {
    setShowNextButton(false)
    return () => {
      setShowNextButton(true)
    }
  })
    return (
        <div> 
            <div className={classes.options}>
        {options.map(({optionKey, optionValue})=> <OptionButton key={optionKey} optionValue={optionValue} optionKey={optionKey} handleClick={setSelectedOption}/>)}
        </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "100%",
        maxWidth: "50ch",
      },
    },
    options: {
      display: "flex",
      flexDirection: "column",
      padding: "0 5rem 0 5rem",
      "@media (max-width: 768px)": {
        padding: "0"
      }
    }
  
  }));

export default DetailOptions;