import React from "react";

import CardActionArea from "@material-ui/core/CardActionArea";
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import { makeStyles } from "@material-ui/core/styles";

import DeleteOutline from "@material-ui/icons/DeleteOutline";

const ImageUploadCard = ({
  setStateImageUrl,
  setStateImageFile,
  stateImageUrl,
}) => {
  const classes = useStyles();

  const handleUploadClick = (event) => {
    // Send image as file to state for fireBaseUpload later
    setStateImageFile((currentState) => [
      ...currentState,
      ...event.target.files,
    ]);
    // turn image into data url
    [...event.target.files].map((file) => imageIntoDataUrl(file));
  };

  const imageIntoDataUrl = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      // This sends the data url to the img tag in html
      // And Sends imageUrl to State, should be turned into an Array for more images
      setStateImageUrl((currentState) => [...currentState, reader.result]);
    };
  };

  const imageResetHandler = (imageUrl) => {
    setStateImageUrl((currentState) =>
      currentState.filter((url) => url !== imageUrl)
    );
  };

  return (
    <React.Fragment>
      <input
        accept="image/*"
        className={classes.input}
        color="primary"
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleUploadClick}
      />
      <label color="primary" htmlFor="contained-button-file">
        <Fab color="primary" component="span" className={classes.button}>
          <AddPhotoAlternateIcon />
        </Fab>
      </label>
      <div className={classes.grid3}>
        {stateImageUrl &&
          stateImageUrl.map((imageUrl) => (
            <CardActionArea
              key={imageUrl}
              onClick={() => imageResetHandler(imageUrl)}
              classes={{
                root: classes.actionArea,
                focusHighlight: classes.focusHighlight,
              }}
            >
              <img
                width="50%"
                alt="imageupload"
                className={classes.media}
                src={imageUrl}
              />

              <DeleteOutline className={classes.delete} />
            </CardActionArea>
          ))}
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  grid3: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "20px",
    width: "100%",
    margin: "0 auto",
  },
  input: {
    display: "none",
  },
  button: {
    margin: 10,
  },
  actionArea: {
    display: "flex",
    alignItems: "flex-start",
    "&:hover $delete": {
      opacity: 0.7,
    },
  },
  focusHighlight: {},
  delete: { opacity: 0 },
}));

export default ImageUploadCard;
