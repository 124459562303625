import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import JewelryCard from "./../jewelry-card/jewelry-card.component";

const JewelryTypeList = ({ handleNext, jewelryTypes, setShowNextButton }) => {
  const { t } = useTranslation();

  const imgUrlObject = {
    "wedding ring": {
      url: require("../../assets/jewelry-card/platinum_wedding_bands_diamonds_result.jpg"),
    },
    "engagement ring": {
      url: require("../../assets/jewelry-card/blue_platinum_labdiamond_sapphire_engagement_ring.jpg"),
    },
    "signet ring": {
      url: require("../../assets/jewelry-card/silver_signet_ring_round_blue_layered_agate_hand_engraved_result.jpg"),
    },
    cufflinks: {
      url: require("../../assets/jewelry-card/silver_cufflinks_coat_of_arms_result.jpg"),
    },
    pendant: {
      url: require("../../assets/jewelry-card/3d_scan_gold_hand_pendant_result.jpg"),
    },
  };

  useEffect(() => {
    setShowNextButton(false)
    return () => {
      setShowNextButton(true)
    }
  })
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {jewelryTypes &&
          jewelryTypes.map((jewelryType) => (
            <Grid item xs key={jewelryType}>
              <JewelryCard
                imgUrl={imgUrlObject[jewelryType].url}
                title={t("jewelryType." + jewelryType)}
                handleNext={handleNext}
              />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
};

export default JewelryTypeList;
