import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/ToolBar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import JewelryTypeList from "../../components/jewelry-type-list/jewelry-type-list.component";
import JewelryDetails from "../../components/jewelry-details/jewelry-details.component";
import {
  storage,
  createOrderConfirmationMail,
  createNewClientAlertMail,
} from "../../firebase/firebase-utils";
import AdditionalDetails from "../../components/additional-details/additional-details";
import DetailOptions from "../../components/detail-options/detail-options"

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered by "}
      <Link color="inherit" href="https://tidanapp.com/">
        TIDAN
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  logo: {
    height: "2rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  projectDetails__grayLine: {
    backgroundColor: "var(--color-gray)",
  width: "100%",
  height: "0.15rem",
  position: "relative",
  marginBottom: "2rem",
  },
  projectDetails__bronzeLine: {
    backgroundColor: "var(--color-bronze)",
  height: "100%",
  position: "absolute",
  
  },
  projectDetails__lineComment: {
    color: "var(--color-dark-gray)",
    fontSize: "0.8rem",
  }
}));

const validEmailRegex = RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);

const GetProjectDetails = ({
  companyName,
  siteUrl,
  logoUrl,
  jewelryTypes,
  companyMail,
  ReactGa,
  ReactPixel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [jewelryType, setJewelryType] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  // Send this to upload Image Component and pass down to review details
  const [imageAsFileArray, setimageAsFileArray] = useState([]);
  const [imageUrlsOnFirebase, setImageUrlsOnFirebase] = useState([]);
  const [customerMessage, setCustomerMessage] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerEmailError, setCustomerEmailError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [cachedOption, setCachedOption] = useState("");
  const [showNextButton, setShowNextButton] = useState(true);
  const [showBackButton, setShowBackButton] = useState(true);

  const steps = [
    "Jewelry type",
    "Email",
    "Detail options",
    "Additional Details",
  ];

  useEffect(() => {
    // On last step start Firebase Upload.
    if (activeStep !== steps.length) return;
    // Check if there are images and if this hasn't been fired before
    if (imageAsFileArray.length && !imageUrlsOnFirebase.length) {
      imageAsFileArray.map((imageAsFile) => handleFireBaseUpload(imageAsFile));
    }
    // if no images then go ahead and email Customer.
    if (!imageUrl.length) return emailCustomerAndTidan();

    // If there are images then send email once upload to firebase is finished
    if (imageUrlsOnFirebase.length === imageUrl.length) emailCustomerAndTidan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrlsOnFirebase, activeStep]);

  const jewelryTypeSelected = (type) => {
    if(type === jewelryType) return handleNext();
    
    setJewelryType(type);
  };

  useEffect(() => {
    handleNext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ jewelryType])

  const handleNext = () => {
    if(!jewelryType) return
    // for email validation
    if(activeStep === 1) 
    if(validateEmail()) 
    return setActiveStep(activeStep + 1); 
    else return;

    setActiveStep(activeStep + 1);
  };

  const validateEmail = () => {
    if(validEmailRegex.test(customerEmail)){
      setCustomerEmailError("")
      return true
    }
    else {
      setCustomerEmailError(t("projectDetails.Please enter a valid email"))
      return false
    }
  }

  const handleBack = () => {
    if(activeStep >= 3) {setCachedOption(selectedOption); setSelectedOption("");}
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JewelryTypeList
            handleNext={jewelryTypeSelected}
            jewelryTypes={jewelryTypes}
            setShowBackButton={setShowBackButton}
            setShowNextButton={setShowNextButton}
          />
        );
      case 1:
        return (
          <JewelryDetails
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            customerEmailError={customerEmailError}
          />
        );
        case 2:
        return (
          <DetailOptions
          setSelectedOption={setSelectedOption}
            handleNext={handleNext}
            selectedOption={selectedOption}
            setShowNextButton={setShowNextButton}
          />
        );
        case 3:
        return (
          <AdditionalDetails
            setStateImageUrl={setImageUrl}
            setStateImageFile={setimageAsFileArray}
            setCustomerMessage={setCustomerMessage}
            stateImageUrl={imageUrl}
            customerMessage={customerMessage}
            selectedOption={selectedOption}
            cachedOption={cachedOption}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleFireBaseUpload = (imageAsFile) => {
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }

    const currentTime = Date.now();

    const uploadTask = storage
      .ref(`/images/${currentTime + "-" + imageAsFile.name}`)
      .put(imageAsFile);

    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
        console.error(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("images")
          .child(currentTime + "-" + imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageUrlsOnFirebase((currentState) => [
              ...currentState,
              fireBaseUrl,
            ]);
          });
      }
    );
  };

  const emailCustomerAndTidan = () => {
    ReactGa.event({
      category: "Lead",
      action: "Place order",
    });
    ReactPixel.trackCustom("Place order", {
      jewelryType,
      customerEmail,
    });
    createOrderConfirmationMail({
      customerEmail: customerEmail,
      imageUrls: imageUrlsOnFirebase,
      customerMessage: customerMessage,
      jewelryType: jewelryType,
      companyName: companyName,
    });
    createNewClientAlertMail(
      {
        customerEmail: customerEmail,
        imageUrls: imageUrlsOnFirebase,
        customerMessage: customerMessage,
        jewelryType: jewelryType,
      },
      companyMail
    );
  };

  useEffect(() => {
    const listener = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleNext()
    }
  };
  document.addEventListener("keydown", listener);
  return () => {
    document.removeEventListener("keydown", listener);
  };
});

const percentage = () => activeStep/steps.length*100;

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="inherit" className={classes.appBar}>
        <ToolBar>
          <Link href={siteUrl} className={classes.logo}>
            <img src={logoUrl} className={classes.logo} alt="logo" />
          </Link>
        </ToolBar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
        <span className={classes.projectDetails__lineComment}>{percentage()}% {t('projectDetails.completed')}</span>
        <div className={classes.projectDetails__grayLine}>
            <div
              className={classes.projectDetails__bronzeLine}
              style={{ width: percentage() + '%' }}
            />
          </div>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {t("projectDetails.Thank you for your order")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("projectDetails.We have emailed your order confirmation") +
                    " " +
                    t("projectDetails.We will be in touch within 24 hours")}
                </Typography>
                <Link href={siteUrl}>{t("projectDetails.Go to home")}</Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {(activeStep > 0 && showBackButton) && (
                    <Button onClick={handleBack} className={classes.button}>
                      {t("projectDetails.Back")}
                    </Button>
                  )}
                  {showNextButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? t("projectDetails.Confirm")
                      : t("projectDetails.Next")}
                  </Button>)}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
};

export default GetProjectDetails;
