import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const JewelryDetails = ({
  setCustomerEmail,
  customerEmail,
  customerEmailError,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classes.root}
        autoComplete="off"
      >
        <div>

          <TextField
          error={customerEmailError}
            id="outlined-static"
            label={t("jewelryDetails.Your email*")}
            placeholder={t("jewelryDetails.email@emailcom")}
            onChange={(e) => setCustomerEmail(e.target.value)}
            variant="outlined"
            value={customerEmail}
            helperText={customerEmailError}
          />
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
      maxWidth: "50ch",
    },
  },
}));

export default JewelryDetails;
