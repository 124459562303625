import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: require("./locales/en/translation.json"),
  },
  sv: {
    translation: require("./locales/sv/translation.json"),
  },
};

let userLang = navigator.language || navigator.userLanguage;
userLang = userLang.split("-")[0];
if (userLang === "sv") {
  userLang = "sv";
} else {
  userLang = "en";
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: userLang,
    debug: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
